.tile-holder {
  /* display: flex; */
  /* overflow: auto; */
  background: #718792;
  padding: 16px 8px;
}

.tile {
  height: 140px;
  width: 140px;
  background-color: #fff;
  border-radius: 4px;
  margin: 1em;
  flex: 0 0 140px;
  overflow: hidden;
  position: relative;
}
.tile-center-content {
  display: flex;
  position: relative;
  flex-direction: column;
}
.tile-center-content canvas {
  margin: auto auto;
}

.tile-image {
  position: absolute;
  bottom: 0;
  height: 120px;
  width: 100%;
  border-top: 1px solid rgb(55, 71, 79);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.vault-floor-empty {
  height: 100%;
  width: 100%;
  padding: 2em;
  font-size: 2em;
  color: #efefef;
  text-align: center;
}

.remove-plan-button {
  position: absolute;
  right: 4px;
  top: 4px;
}

.tile-title {
  padding: 4px;
}

#root .ant-layout {
  position: relative;
}

#root,
#root > div,
section.ant-layout {
  height: 100%;
}

#boardCanvas {
  /* height: calc(100vh - 48px); */
  height: 100%;

  width: 100vw;
  overflow: hidden;
}

.footstep-help {
  margin-top: 20px;
  width: 90px;
  transform: scale(1) scaleZ(1) rotateX(50deg);
  transform-origin: 0% 0%;
  perspective: 640;
  -webkit-transform: scale(1) scaleZ(1) rotateX(70deg);
  -webkit-transform-origin: 0% 0%;
  -webkit-perspective: 640;
  -webkit-perspective-origin: 50% 0%;
  -moz-transform: scale(1) scaleZ(1) rotateX(70deg);
  -moz-transform-origin: 0% 0%;
  -moz-perspective: 640;
  -moz-perspective-origin: 50% 0%;
  -o-transform: scale(1) scaleZ(1) rotateX(70deg);
  -o-transform-origin: 0% 0%;
  -o-perspective: 640;
  -o-perspective-origin: 50% 0%;
  -ms-transform: scale(1) scaleZ(1) rotateX(70deg);
  -ms-transform-origin: 0% 0%;
  -ms-perspective: 640;
  -ms-perspective-origin: 50% 0%;
  transform: scale(1) scaleZ(1) rotateX(70deg);
  transform-origin: 0% 0%;
  perspective: 640;
  perspective-origin: 50% 0%;
}

.gallery-list-item {
  background-color: #f5f5f6;
  padding: 10px;
  box-sizing: border-box;
}

.gallery-list-item-image {
  width: 172px;
  height: 129px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}

.gallery-list-item-image img {
  max-height: 100%;
  max-width: 100%;
  outline: 2px solid #fff;
  padding: 5px;
  cursor: pointer;
}

.gallery-list-item--selected .gallery-list-item-image img {
  outline: 2px solid #b0bec5;
  outline-offset: 5px;
}

.gallery-list-column {
  box-shadow: 0px 0px 10px 0px rgba(28, 49, 58, 0.7);
  z-index: 10;
}

.gallery-list-holder {
  position: relative;
  height: calc(100vh - 126px);
  overflow: auto;
  padding-bottom: 10px;
  -webkit-box-shadow: 6px 0px 10px 0px rgba(28, 49, 58, 0.7);
  -moz-box-shadow: 6px 0px 10px 0px rgba(28, 49, 58, 0.7);
  box-shadow: 6px 0px 10px 0px rgba(28, 49, 58, 0.7);
}
.gallery-list-item-holder {
  border-bottom: 1px solid #b0bec5!important;
}
.gallery-list-item-holder.gallery-list-item--selected {
  border: 2px solid #d1c4e9;
}

.gallery-list-item-holder .ant-list-item-extra {
  margin-left: 10px;
}

.gallery-list-item-holder .ant-list-item-meta-title {
  cursor: pointer;
}

.gallery-list-item-holder .ant-list-item-meta-title {
  cursor: pointer;
  padding-left: 10px;
}

.gallery-list-item-holder .ant-list-item-meta-title:hover {
  background-color: #4527a0;
  color: #fff;
}

.gallery-list-item--selected .gallery-list-item {
  background-color: #fff;
}

.map-info-window {
  border: 1px solid #d1c4e9;
  padding: 10px;
  text-align: center;
}

.map-info-window-link {
  cursor: pointer;
  font-size: 15px;
}

.map-info-window-link:hover,
.icon-link:hover {
  color: #4527a0;
}

.title-logo-holder {
  animation: moveIt 7s ease-in-out 1;
  transform-origin: 50% 5px;
}

@keyframes moveIt {
  0% {
    transform: rotate(-14deg);
  }
  20% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-7deg);
  }
  60% {
    transform: rotate(6deg);
  }
  80% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0);
  }
}

.landing-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  /* top:47px; */
  z-index: 20;
}

.title-row-title {
  margin: 16px;
  margin-bottom: 0;
}

.title-row-no-title {
  height: 75px;
}

.title-row-no-title .help-modal-button {
  margin-right: 16px;
  margin-top: 16px;
}

.help-modal-button {
  /* width: 32px; */
  position: absolute;
  right: 0;
  top: 4px;
}

.landing-loading-canvas {
  height: 100%;
  width: 100vw;
  position: absolute;
  bottom: 0;
}

.title-logo {
  margin-top: -7px;
  z-index: 25;
}

.landing-loading {
  /* opacity: .9; */
}

.landing-holder {
  height: calc(100% - 50px);
  top: 47px;
  width: 100vw;
  z-index: 20;
  position: absolute;
  background-color: #273238;
}

.focus-eye {
  border: 1px solid;
  border-image: repeating-linear-gradient(
      135deg,
      rgba(76, 76, 76, 0.46) 0%,
      rgba(83, 55, 94, 0.46) 2%,
      rgba(90, 34, 112, 0.51) 4%,
      rgba(102, 37, 37, 0.78) 15%,
      rgba(104, 35, 62, 0.91) 20%,
      rgba(112, 29, 142, 0.73) 36%,
      rgba(114, 73, 102, 0.55) 53%,
      rgba(104, 53, 109, 0.46) 61%,
      rgba(97, 41, 114, 0.54) 66%,
      rgba(89, 80, 29, 0.7) 76%,
      rgba(28, 28, 28, 0.94) 91%,
      rgba(24, 24, 24, 1) 95%,
      rgba(19, 19, 19, 1) 100%
    )
    40;
  filter: blur(4px);
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  background-clip: content-box;
  background-blend-mode: screen;
  cursor: none !important;
}

.focus-screen {
  /* cursor:none !important; */
}

.focus-cursor {
  width: 40px;
  height: 40px;
  border: 2px solid #fff8e1;
  /* border-radius: 50%; */
  position: absolute;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  /* animation: cursorAnim .7s infinite alternate; */
  pointer-events: none;
}

.focus-cursor::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  border: 8px solid #6345ad;
  /* border-radius: 50%; */
  opacity: .5;
  top: -2px;
  left: -2px;
  /* animation: cursorAnim2 .7s infinite alternate; */
}

@keyframes cursorAnim {
  from {
      transform: scale(1);
  }
  to {
      transform: scale(.9);
  }
}

@keyframes cursorAnim2 {
  from {
      transform: scale(.9);
  }
  to {
      transform: scale(.65);
  }
}

@keyframes cursorAnim3 {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(3);
  }
  100% {
      transform: scale(1);
      opacity: 0;
  }
}

.focus-escape {
  position: absolute;
  top:130px;
  right: 30px;
width:30px;
height:30px;
border: 2px solid #6345ad;
border-radius: 50%;
background-color: #333;
pointer-events: none;

}

.focus-escape:after {
content: "X";
font-size: 50px;
position: absolute;
top:-26px;
right: -3px;
color:#fff8e1
}

.rte-wrapper {
  margin-bottom: 25px !important;
    height: 260px !important;
}

.rte-editor {
  height:200px !important;
  max-width: 100%;
  /* height: auto; */
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #F5F5F6;
  background-image: none;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
}
.rte-editor__focus {
border-color: #6345ad;
border-right-width: 1px !important;
outline: 0;
-webkit-box-shadow: 0 0 0 2px rgb(69 39 160 / 20%);
box-shadow: 0 0 0 2px rgb(69 39 160 / 20%);
}