.page-header-area-relative {
  position: relative;
  top: -16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  }

.content-column-holder {
  display: flex;
  justify-content: center;
  width: 100%;
}

.content-column {
  width: 700px;
  display: flex;
  flex-direction: column;
}

.button-holder {
  width: 100%;
  margin: 10px;
}

.primary-button,
.view-header__add-button {
  float: right;
  border: 1px solid #cfd8dc;
  font-size: 14px;
  border-radius: 9px;
  font-weight: 300;
  color: #cfd8dc;
  margin: 0 1.5em;
}
.primary-button:hover,
.view-header__add-button:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.2);
  color: #424242;
  background-color: #fafafa;
}

.instructions-holder {
  background-color: #eceff1;
  /* color: ; */
  /* border-radius: 5px; */
  padding: 10px;
  margin: 1em 0 0 auto;
}
.instructions-holder ul {
  list-style-type: none;
  padding-left: 5px;
  margin: 0;
}
.canvas-holder {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  /* height: 100vh; */
  flex-direction: column;
}
canvas {
  /* cursor: crosshair; */
}

.floorplan-title-field {
  padding: 0.4em;
  font-size: 1.3em;
  background: transparent;
  border: none;
  border-bottom: 2px solid #37474f;
  color: #eceff1;
  outline: none;
  width: calc(100% - 8em);
}
.floorplan-title-field:focus {
  background: transparent;
  color: #fff;
}
