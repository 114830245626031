.navigation-holder {
  /* position: absolute; */
  position: fixed;
  
  bottom: 0;
  left: 0;
  z-index: 1;
}

.navigation-list {
  background-color: #efebe9;
}

.navigation-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navigation-list li {
  padding: 8px;
  border-bottom: 2px solid #fff8e1;
  margin: 0;
}
.navigation-list a {
  text-decoration: none;
  color: #263238;
}

.art-details {
  position:absolute;
  z-index: 1;
  bottom:0;
  width: 100%;
  padding: 0 2vw;
}
.art-details-card {

  background-color: #fff;
  padding:15px 25px;
  animation: 12s ease-out 0s 1 slideInFromBottom;
  -webkit-box-shadow: 1px -5px 10px -2px rgba(0,0,0,0.75);
-moz-box-shadow: 1px -5px 10px -2px rgba(0,0,0,0.75);
box-shadow: 1px -5px 10px -2px rgba(0,0,0,0.75);
margin: 0 auto;
max-width: 700px;
}

.art-details-title {
  padding-right: 50px;
  position: relative;
}

.art-details-down-button {
  background-color: #fff;
  position: absolute; 
  right: 0;
   bottom:0;
   padding: 5px 15px;
   cursor: pointer;
}

.art-details-card-open-holder {
  /* padding:15px 25px; */
max-width: 700px;
margin: auto;
position: relative;
  padding: 10px 2vw;

}

.art-details-card-open-holder .art-details-down-button {
  box-shadow: 1px -5px 10px -2px rgba(0,0,0,0.75);

}

.art-details-description {
  overflow-y: auto;
  max-height: 33vh;
  padding-right: 10px;
  word-break: break-word;
}
.art-details-description::-webkit-scrollbar {
  -webkit-appearance: none;
}

.art-details-description::-webkit-scrollbar:vertical {
  width: 11px;
}
.art-details-description::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

button.ant-btn.ant-btn-primary {
border-color: #718792;
}

.page-title-2 {
  color: rgb(255, 255, 255);
/* float: left;
width: calc(100% - 50px); */
padding: 0 50px;
/* font-size: 5vw; */
}

.page-title-2 span:hover {
color:#d1c4e9;
cursor:pointer;
}


