.center-standard-form {
margin-bottom: 230px
}

.page-header-area {
position: absolute;
top:48px;
  width: 100%;
  background-color: rgb(55, 71, 79);
  color: rgba(0, 0, 0, 0.85);
  padding: 0 1vw;
  min-height: 71px;
}



@primary-color: #4527a0;@layout-header-background: #1c313a;@body-background: #263238;@layout-body-background: #263238;@component-background: #F5F5F6;@processing-color: #7e57c2;