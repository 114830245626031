.vault-container {
  /* position: absolute; */
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
}

.vault-container.open {
  width: 100vw;
  z-index: 1;
  -webkit-box-shadow: 1px -5px 10px -2px rgba(0,0,0,0.75);
-moz-box-shadow: 1px -5px 10px -2px rgba(0,0,0,0.75);
box-shadow: 1px -5px 10px -2px rgba(0,0,0,0.75);

}

.vault-doors {

  display: flex;
}

.open .vault-doors {
  /* display: flex;
  justify-content: stretch;*/
  flex: 1; 
}

.closed .vault-doors {
  display: none;
}

.vault-floors-container {
  overflow-y: auto;
  flex: 1;
  position: relative;
  height: 17em;
  flex-direction: column;
}
.vault-button-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #718792;
  /* border: 3px solid #ccc; */
  min-height: 5em;
}

.closed .vault-button-panel {
  -webkit-box-shadow: -5px -5px 10px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: -5px -5px 10px -2px rgba(0,0,0,0.75);
  box-shadow: -5px -5px 10px -2px rgba(0,0,0,0.75);
}

.vault-button {
  padding: 4px 15px;
  border-radius: 3px;
  background-color: #4527a0;
  color: #fff;
  text-align: center;
  margin: .5em;
}

.elevator-panel {
  width: 140px;
  margin-left: auto;
  padding: 10px 14px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  color: hsla(0, 0%, 20%, 1);
  background-color: #cfd8dc;
}

.elevator-current-floor {
  padding: 0.4em 1em;
  color: #fff;
  background-color: #37474f;
  height:34px;
}

.elevator-floors-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.elevator-floors-list-item {
  /* padding: px 0; */
  cursor: pointer;
  display:inline-block;
  width: 100%;
  padding: 1px 0 1px 5px;
}

.elevator-floors-list-item:hover {
  /* padding: px 0; */
color: #fff;
background-color: #4527a0;
}
.floor-container {
  position: relative;
  width: 100%;
  /* height: 15em; */
}

.floor-wrapper {
  /* display: flex; */
  overflow: auto;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 2em 1em 1em 1em;
  background-color: #cfd8dc;
}


.floor-title {
  position: absolute;
  padding: 5px;
  margin: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
  color:#4527a0;
  font-weight: 700;
}

/*Llight Floor */
.gutter-row {
  border-radius: 5px;
}

.gutter-floor .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: aliceblue;
  padding: 5px 10px;
  border-radius: 5px;
}

.gutter-row button {
  margin-right: 8px;
}

.control-item {
  padding: 4px 0;
}

.control-item-name {
  line-height: 40px;
}

.control-slider {
  height: 35px;
}

.control-slider > div {
  display: inline;
}

.control-slider-control {
  float: right;
  width: 170px;
}

.tile-selected-cancel {position: absolute;
margin: auto;
width: 100%;
text-align: center;
padding: 6px;
background: rgba(1,255,200,.3);
}
/* floorplan dropdown */

.master, .user,  .master canvas, .user canvas{
  cursor: pointer;
  }
.master:hover, .user:hover {
background-color: #4527a0;
color: #fff;
}
.header-tile-title {
  text-align: center;
font-size: 20px;
width: 100%;
}
                


.header-tile-outer-holder {
  background-color: #CFD8DC;
  padding-left: 1em;
  padding-right: .1em;

}

.header-tile-holder {
  max-height: 275px;
  overflow:auto;
  background-color: #718792;
  margin-bottom: 1em;
  padding:10px;
}
.header-tile.master:hover, .header-tile.user:hover {
background-color: #4527a0;

}
.header-tile.master, .header-tile.user {
padding: 0px;

  background-color: #fff;
  /* color: #666; */
  padding-bottom: 5px;
  width: 165px;
  text-align: center;
  }

.header-button-col {
  background-color: #CFD8DC;
  text-align: center;
  margin-bottom: 1em;
  /* width: 200px; */
}

.floorplan-new-button {
  border-top: 1px solid #b0bec5;
  border-bottom:1px solid #b0bec5;
/* margin:auto; */
width: 100%;
text-align: center;
margin: 2px;

}
.master:after {
  position:absolute;
  content: "H";
  bottom:2px;
  right:10px;
  padding:3px 8px;
  background-color: #4527a0;
  color:#fff;
  opacity:.8;
}

.borrowed .borrowed-badge {
  position: absolute;
  bottom: 2px;
  right: 10px;
  padding:3px 8px;
  background-color: #ff8f00;
  color:#fff;
  opacity:.8;
}

.borrower-details-holder {
  border: 2px solid #ff8f00;
  padding: 10px;
  cursor: pointer;

}

.borrower-details-holder:hover {
  color: #4527a0; 
  fill: #4527a0;
}

.footsteps-link {
  transform: rotate(-35deg);
  float:right;
}

.gallery-edit-dropdown .ant-select-item.ant-select-item-option:hover{
  background-color: #4527a0;
  color: #fff;
}